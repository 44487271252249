<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">优惠券列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-tabs @tab-click="handleClick" v-model="search.coupon_type">
				<el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.name" :name="item.value">
				</el-tab-pane>
			</el-tabs>
			<div class="d-flex flex-wrap align-items-center">
				<div>添加时间：</div>
				<el-date-picker style="width: 400px;" class="m-1" size="small" @change="changeTime" v-model="time"
					type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center ml-3">
					<div>是否发布：</div>
					<el-button @click="handleClickStatus(0)" :type="search.status == 0 ?'primary':''" size="mini">全部
					</el-button>
					<el-button @click="handleClickStatus(10)" class="ml-2" :type="search.status == 10 ?'primary':''"
						size="mini">否</el-button>
					<el-button @click="handleClickStatus(20)" class="ml-2" :type="search.status == 20 ?'primary':''"
						size="mini">是</el-button>
				</div>
				<div class="d-flex align-items-center ml-3">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%" :default-expand-all="true">
				<!-- <el-table-column type="expand">
					<template slot-scope="scope">
						<div class="p-0 m-0">
							数量：总数量：{{scope.row.stock_num + scope.row.receive_num}} 限领数量：{{scope.row.limit_num}}
							已领数量：{{scope.row.receive_num}} 核销人数：1000
						</div>
						<div class="p-0 m-0">
							劵数据：浏览人数：100 浏览次数：1000 分享人数：1000 分享次数：1000
						</div>
					</template>
				</el-table-column> -->
				<el-table-column align="center" width="90" prop="coupon_id" label="劵ID"></el-table-column>
				<el-table-column align="center" label="劵类型" width="90">
					<template slot-scope="scope">
						<el-tag size="mini">{{ scope.row.coupon_type.text }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="劵名称" prop="name">
					<template slot-scope="scope">
						<template>
							{{ scope.row.name }}<br>
							{{ scope.row.condition }}
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="所属商家" width="190">
					<template slot-scope="scope">
						<div class="d-flex align-items-center">
							<viewer>
								<img style="width: 45px;height: 45px;" :src="scope.row.merchant.logo">
							</viewer>
							<div class="ml-3" style="text-align: left;">
								{{scope.row.merchant.merchant_name}}<br>
								{{scope.row.merchant.mobile}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="有效期">
					<template slot-scope="scope">
						<template v-if="scope.row.expire_type == 10">
							<span>固定时间: {{scope.row.start_time}} - {{scope.row.end_time}}</span>
						</template>
						<template v-if="scope.row.expire_type == 20">
							<span>领取后 {{scope.row.start_day}} 天内有效</span>
						</template>
						<template v-if="scope.row.expire_type == 30">
							<span>领取后 {{scope.row.start_day}} 天- {{scope.row.end_day}} 天内有效</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="是否发布" width="90">
					<template slot-scope="scope">
						<el-tag size="mini" type="success">{{ scope.row.status.text }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间" width="180">
					<template slot-scope="scope">
						<span style="">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<!-- <router-link :to="{name:'coupon/detail',params: {coupon_id:scope.row.coupon_id}}">
							<el-button type="text" size="mini" class="table-btn">详情</el-button>
						</router-link> -->
						<el-button type="text" size="mini" @click="detail(scope.row.coupon_id)" class="table-btn">详情</el-button>
						<router-link class="ml-2" :to="{name:'coupon/record',params: {coupon_id:scope.row.coupon_id}}">
							<el-tooltip class="item" effect="dark" content="查看领取记录" placement="top">
								<el-button type="text" size="mini">查看领取</el-button>
							</el-tooltip>
						</router-link>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="详情" :visible.sync="couponVisible" append-to-body>
				<template v-if="coupon != null">
					<el-divider><span style="font-weight: 800;">基本信息</span></el-divider>
					<p>劵类型：<el-tag size="small">{{coupon.coupon_type.text}}</el-tag></p>
					<p>劵名称：{{coupon.name}}</p>
					<p>使用条件：{{coupon.condition}}</p>
					<p>使用须知：{{coupon.intro}}</p>
					
					<el-divider><span style="font-weight: 800;">劵信息</span></el-divider>
					<p>当前库存数量：<span style="color: red;font-weight: 800;">{{coupon.stock_num}}</span> 张</p>
					<p>用户限领张数：<span style="color: red;font-weight: 800;">{{coupon.limit_num}}</span> 张</p>
					<p v-if="coupon.expire_type == 10">
						有效期：{{coupon.start_time}} - {{coupon.end_time}}
					</p>
					<p v-if="coupon.expire_type == 20">
						有效期：领取后 <span style="color: red;font-weight: 800;">{{coupon.start_day}}</span> 天内有效
					</p>
					<p v-if="coupon.expire_type == 30">
						有效期：领取后 <span style="color: red;font-weight: 800;">{{coupon.start_day}}</span> 天- <span
							style="color: red;font-weight: 800;">{{coupon.end_day}}</span> 天内有效
					</p>
					<p v-if="coupon.is_limit_time == 20 && coupon.expire_type != 10">
						领取截止日期：{{coupon.limit_time}}
					</p>
					<p>
						可用时段（日期）： <el-tag size="small" v-if="coupon.hour.week.indexOf(10) > -1">周一</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(20) > -1">周二</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(30) > -1">周三</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(40) > -1">周四</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(50) > -1">周五</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(60) > -1">周六</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(70) > -1">周日</el-tag>
					</p>
					<p>
						可用时段（时间）： {{coupon.hour.time.start}} - {{coupon.hour.time.end}}
					</p>
					
					<el-divider><span style="font-weight: 800;">活动规则</span></el-divider>
					<p>
						限制领取地区：<span v-if="coupon.rule.region_limit == 20">{{coupon.rule.province_name}}
							{{coupon.rule.city_name}} {{coupon.rule.region_name}}</span><span v-else>不限制</span>
					</p>
					<p>核销后赠送：<span v-if="coupon.rule.clerk_give == 20">开启</span><span v-else>关闭</span></p>
					<p>禁止赠送：<span v-if="coupon.rule.forbid_give == 20">开启</span><span v-else>关闭</span></p>
					<p>禁止分享：<span v-if="coupon.rule.forbid_share == 20">开启</span><span v-else>关闭</span></p>
					<p>分享更优惠（裂变卷）：<span v-if="coupon.rule.share_minus == 20">开启</span><span v-else>关闭</span></p>
					<p>用户信息收集：<span v-if="coupon.rule.is_user_info == 20">开启</span><span v-else>关闭</span></p>
					
					<el-divider><span style="font-weight: 800;">商家详情信息</span></el-divider>
					<p>商家名称：{{coupon.couponMerchant.merchant_name}}</p>
					<p class="d-flex align-items-center">LOGO：<viewer><img class="ml-3" style="width: 45px;height: 45px;"
								:src="coupon.couponMerchant.logo"></viewer>
					</p>
					<p>商家电话：{{coupon.couponMerchant.mobile}}</p>
					<p>微信号：{{coupon.couponMerchant.wechat}}</p>
					<p>商家地址：{{coupon.couponMerchant.address}}</p>
					<p>详细地址：{{coupon.couponMerchant.address_detail}}</p>
					<el-divider><span style="font-weight: 800;">图文详情</span></el-divider>
					<template v-for="(item,index) in coupon.couponImageText">
						<template v-if="item.type == 10">
							<p>{{item.guidance}}：{{item.content}}</p>
						</template>
						<template v-else>
							<p class="d-flex align-items-center">
								<viewer><img class="mr-2" style="width: 45px;height: 45px;" :src="item.image"></viewer>
								<span>{{item.content}}</span>
							</p>
						</template>
					</template>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				tabs: [{
						name: '全部',
						value: '0'
					},
					{
						name: '兑换券',
						value: '10'
					},
					{
						name: '满减券',
						value: '20'
					},
					{
						name: '折扣卷',
						value: '30'
					},
					{
						name: '代金券',
						value: '40'
					},
					{
						name: '裂变卷',
						value: '50'
					}
				],
				search: {
					s: 'store/coupon/index',
					coupon_type: '0',
					keyword: '',
					status: 0,
					page: 1,
					start_time: null,
					end_time: null,
					merchant_user_id: this.$route.params.merchant_user_id,
				},
				time: [],
				couponVisible: false,
				coupon: null,
				couponId: null,
			}
		},
		created() {
			this.getList();
		},
		methods: {
			detail(couponId){
				this.couponId = couponId;
				this.couponVisible = true;
				this.getCouponDetail();
			},
			handleClick() {
				this.toSearch()
			},
			handleClickStatus(status) {
				this.search.status = status;
				this.toSearch()
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			changeTime() {
				if (this.time) {
					this.search.start_time = this.time[0];
					this.search.end_time = this.time[1];
				} else {
					this.search.start_time = null;
					this.search.end_time = null;
				}
				this.toSearch();
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getCouponDetail() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/coupon/detail',
						coupon_id: this.couponId
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.coupon = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>

</style>
